import { Card } from "flowbite-react";

export const CardItems = (props) => {
  return (
    <div className="max-w-sm">
      <Card>
        {props.icon}
        <h3 className="tracking-tight text-primaryDark dark:text-white">
          {props.card_title}
        </h3>
        <p className="content_sm1 text-primaryDark dark:text-gray-400">
          {props.card_content}
        </p>
      </Card>
    </div>
  );
};
