import { Cart, PiggyBank, Travel } from "../icons";

export const OptionsObj = () => {
  const optionItem = [
    {
      icon: <Cart />,
      title: "Retail & Ecommerce",
    },
    {
      icon: <PiggyBank />,
      title: "Banking & Finance",
    },
    {
      icon: <Travel />,
      title: "Travel & Hospitality",
    },
  ];

  return optionItem;
};
