export const Options = (props) => {
  return (
    <div className="flex text-center lg:text-start h-[100px] w-max-[480px] options_item">
      <div
        id="options"
        className="relative w-full flex text-start cursor-pointer items-center rounded-[20px] px-5 py-4 hover:shadow-md bg-white transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 border border-[#BECAD6] hover:border-theme"
      >
        <div className="shrink-0 mr-5">{props.icon}</div>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="text-sm">
              <p className="content_sm4">{props.title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
