import { CardItems } from "../components/card";
import { CardObj } from "../components/utilities/card-object";
import { Hero } from "../components/hero";
import { Options } from "../components/options";
import { Section } from "../components/section";
import { SectionCarousel } from "../components/section-carousel";
import { OptionsObj } from "../components/utilities/options-object";

function Main() {
  return (
    <>
      <div className="bg-themeLight">
        <Hero
          title={<>Innovating your Business to New Possibilities</>}
          desc={
            <>
              End-to-end product development services &#38; support for customer
              first teams.
            </>
          }
          img={"1080x1080-using-comp.png"}
        />
      </div>
      <div id="services" className="section_div">
        <Section
          title="What we do for you"
          content={
            <>
              <div className="hidden sm:grid grid-cols-1 gap-x-10 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
                {CardObj().map((item, index) => {
                  return (
                    <CardItems
                      key={index}
                      icon={item.icon}
                      card_title={item.card_title}
                      card_content={item.card_content}
                    />
                  );
                })}
              </div>
              <SectionCarousel />
            </>
          }
        />
      </div>
      <div className="section_div">
        <Section
          title=""
          content={
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="flex justify-center items-center sm:w-[401px]">
                <h2 className="inline-flex text-center lg:text-start items-baseline text-3xl sm:text-5xl md:!leading-[70px] max-w-[85%]">
                  Get in touch
                </h2>
              </div>
              <div className="flex justify-center items-center sm:w-[401px]">
                <a
                  href="/#contact"
                  className="main-button inline-flex  items-center !bg-primaryDark"
                >
                  <span className="text-sm font-medium"> Get in touch </span>
                </a>
              </div>
            </div>
          }
          customStyle={"bg-themeLight sm:rounded-[40px]"}
        />
      </div>
      <div id="about" className="section_div">
        <Section
          title=""
          content={
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="relative rounded-lg">
                <img
                  alt="hero"
                  src={"1080x1080-agile-wall 1.png"}
                  className="inset-0 object-cover rounded-[40px] w-[514px] h-[518px]"
                />
              </div>
              <div className="lg:py-5">
                <h2 className="inline-flex items-baseline text-3xl sm:text-5xl md:!leading-[70px] max-w-[85%] gap-6">
                  We put our clients objectives first
                </h2>

                <p className="mt-4 text-gray-600">
                  Our identity is founded on a deep-rooted culture of excellence
                  and a continued commitment to delivering high-quality digital
                  products to our customers. We take pride in our ability to
                  deliver excellent solutions to our clients around the world.
                </p>
              </div>

              <div className="flex items-center mt-10">
                <h2 className="inline-flex items-baseline text-3xl sm:text-5xl md:!leading-[70px] max-w-[85%]">
                  Industries we specialize in
                </h2>
              </div>

              <div className="lg:py-5 grid gap-y-4">
                {OptionsObj().map((item, index) => {
                  return (
                    <Options key={index} icon={item.icon} title={item.title} />
                  );
                })}
              </div>
            </div>
          }
        />
      </div>

      <div id="contact" className="section_div">
        <Section
          title="Get in touch"
          content={
            <div className="md:w-[1035px]">
              <embed
                src="https://tfaforms.com/5029975"
                className="flex h-[700px] sm:h-[500px] justify-center w-full bg-transparent"
              />
            </div>
          }
          customStyle={"bg-themeLight sm:rounded-[40px] !mb-[138px]"}
        />
      </div>
    </>
  );
}

export default Main;
