export const LogoDark = () => {
  return (
    <svg
      width="190"
      height="32"
      viewBox="0 0 190 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.024 10.4499C132.173 10.9939 133.058 11.7715 133.686 12.7787C134.311 13.7858 134.624 14.9545 134.624 16.2846C134.624 17.6147 134.311 18.777 133.686 19.7714C133.061 20.7658 132.173 21.535 131.024 22.081C129.876 22.6271 128.545 22.8991 127.032 22.8991H122V9.63611H127.032C128.545 9.63611 129.876 9.90808 131.024 10.4542V10.4499ZM130.436 19.4952C131.262 18.7345 131.675 17.6636 131.675 16.2846C131.675 14.9056 131.262 13.8241 130.436 13.0443C129.611 12.2645 128.441 11.8756 126.931 11.8756H124.89V20.6362H126.931C128.444 20.6362 129.613 20.2558 130.436 19.4952Z"
        fill="#071015"
      />
      <path
        d="M136.984 10.6687C136.647 10.3713 136.479 10.0016 136.479 9.55748C136.479 9.11339 136.647 8.74368 136.984 8.44621C137.32 8.14874 137.742 8 138.252 8C138.762 8 139.184 8.14874 139.521 8.44621C139.857 8.74368 140.026 9.11552 140.026 9.55748C140.026 9.99943 139.857 10.3713 139.521 10.6687C139.184 10.9662 138.76 11.115 138.252 11.115C137.745 11.115 137.32 10.9662 136.984 10.6687ZM139.675 12.3686V22.8969H136.788V12.3686H139.675V12.3686Z"
        fill="#071015"
      />
      <path
        d="M149.246 12.6639C149.892 12.9742 150.401 13.3757 150.773 13.8708V12.3686H153.681V22.9713C153.681 23.9466 153.469 24.8178 153.042 25.5848C152.615 26.3519 151.977 26.9596 151.123 27.41C150.27 27.8605 149.239 28.0857 148.031 28.0857C146.407 28.0857 145.079 27.7372 144.038 27.0403C143.001 26.3434 142.413 25.3936 142.274 24.1888H145.141C145.293 24.669 145.618 25.0536 146.121 25.3383C146.624 25.6231 147.23 25.7654 147.948 25.7654C148.787 25.7654 149.467 25.5338 149.989 25.0727C150.51 24.6095 150.773 23.9105 150.773 22.9734V21.3395C150.401 21.8346 149.889 22.2446 149.237 22.574C148.584 22.9033 147.837 23.0691 147 23.0691C146.038 23.0691 145.157 22.8417 144.359 22.3849C143.561 21.928 142.931 21.2864 142.473 20.4556C142.011 19.6269 141.781 18.6729 141.781 17.5956C141.781 16.5183 142.011 15.5877 142.473 14.7632C142.934 13.9388 143.559 13.3056 144.35 12.8637C145.141 12.4196 146.024 12.1986 147 12.1986C147.853 12.1986 148.602 12.3537 149.248 12.6639H149.246ZM150.36 15.9701C150.085 15.5069 149.714 15.1521 149.246 14.9056C148.778 14.6591 148.277 14.5359 147.74 14.5359C147.203 14.5359 146.709 14.657 146.255 14.8971C145.8 15.1372 145.434 15.4899 145.152 15.951C144.871 16.4142 144.73 16.9624 144.73 17.5956C144.73 18.2288 144.871 18.7834 145.152 19.2593C145.434 19.7353 145.805 20.0986 146.266 20.3514C146.728 20.6043 147.219 20.7318 147.74 20.7318C148.261 20.7318 148.778 20.6086 149.246 20.3621C149.714 20.1156 150.085 19.7608 150.36 19.2976C150.634 18.8343 150.773 18.2819 150.773 17.6338C150.773 16.9858 150.634 16.4333 150.36 15.9722V15.9701Z"
        fill="#071015"
      />
      <path
        d="M156.702 10.6687C156.365 10.3713 156.197 10.0016 156.197 9.55748C156.197 9.11339 156.365 8.74368 156.702 8.44621C157.039 8.14874 157.461 8 157.97 8C158.48 8 158.902 8.14874 159.239 8.44621C159.576 8.74368 159.744 9.11552 159.744 9.55748C159.744 9.99943 159.576 10.3713 159.239 10.6687C158.902 10.9662 158.478 11.115 157.97 11.115C157.463 11.115 157.039 10.9662 156.702 10.6687ZM159.393 12.3686V22.8969H156.506V12.3686H159.393V12.3686Z"
        fill="#071015"
      />
      <path
        d="M165.602 14.555V19.6481C165.602 20.003 165.694 20.2601 165.881 20.4173C166.065 20.5766 166.379 20.6553 166.819 20.6553H168.159V22.8969H166.344C163.911 22.8969 162.694 21.8069 162.694 19.629V14.555H161.333V12.3686H162.694V9.76569H165.602V12.3686H168.159V14.555H165.602V14.555Z"
        fill="#071015"
      />
      <path
        d="M170.191 14.7632C170.652 13.9388 171.277 13.3056 172.068 12.8637C172.859 12.4196 173.743 12.1986 174.718 12.1986C175.572 12.1986 176.317 12.358 176.955 12.6746C177.594 12.9912 178.106 13.3906 178.491 13.8708V12.3686H181.399V22.897H178.491V21.3586C178.12 21.8516 177.608 22.2616 176.955 22.5846C176.303 22.9076 175.549 23.0691 174.698 23.0691C173.736 23.0691 172.859 22.8417 172.068 22.3849C171.277 21.928 170.652 21.2864 170.191 20.4556C169.73 19.6269 169.499 18.6729 169.499 17.5956C169.499 16.5183 169.73 15.5877 170.191 14.7632V14.7632ZM178.081 15.9701C177.806 15.5069 177.435 15.1521 176.967 14.9056C176.499 14.6591 175.998 14.5359 175.461 14.5359C174.924 14.5359 174.43 14.657 173.976 14.8971C173.521 15.1372 173.155 15.4899 172.873 15.951C172.592 16.4142 172.451 16.9624 172.451 17.5956C172.451 18.2288 172.592 18.7834 172.873 19.2593C173.155 19.7353 173.526 20.0986 173.987 20.3514C174.448 20.6043 174.94 20.7318 175.461 20.7318C175.982 20.7318 176.499 20.6086 176.967 20.3621C177.435 20.1156 177.806 19.7608 178.081 19.2976C178.355 18.8343 178.494 18.2819 178.494 17.6338C178.494 16.9858 178.355 16.4333 178.081 15.9722V15.9701Z"
        fill="#071015"
      />
      <path
        d="M187.114 8.83508V22.897H184.227V8.83508H187.114Z"
        fill="#071015"
      />
      <g clip-path="url(#clip0_16_106)">
        <path
          d="M36.0857 22.6823H24.3842C24.0747 19.2614 22.3356 16.2976 19.9187 14.5577C20.8914 13.9531 21.8935 13.5845 22.8662 13.5845C27.479 13.5992 35.452 17.551 36.0857 22.6823Z"
          fill="#2797FE"
        />
        <path
          d="M22.8661 13.5992C21.8935 13.5992 20.8913 13.9531 19.9186 14.5724C16.2048 16.8874 12.7562 22.7708 12.1078 26H3C4.6506 20.4115 12.4173 13.6139 18.2238 13.6139H22.8661V13.5992Z"
          fill="#007DF1"
        />
        <path
          d="M46.1513 4C38.5291 4 31.8471 8.01515 28.1008 14.0445C32.1522 15.4969 36.3686 18.5654 36.8225 22.2399C37.7067 13.8351 43.3512 6.84584 50.9999 4H46.1513Z"
          fill="#007DF1"
        />
      </g>
      <g clip-path="url(#clip1_16_106)">
        <path
          d="M59.89 22.9L57.02 18.82C56.67 18.85 56.39 18.86 56.18 18.86H52.9V22.91H51V9.59998H56.19C57.91 9.59998 59.27 10.01 60.26 10.83C61.25 11.65 61.74 12.79 61.74 14.23C61.74 15.26 61.49 16.13 60.98 16.85C60.47 17.57 59.75 18.1 58.81 18.43L61.96 22.89H59.89V22.9ZM58.88 16.46C59.51 15.94 59.83 15.2 59.83 14.24C59.83 13.28 59.51 12.54 58.88 12.03C58.25 11.52 57.33 11.26 56.13 11.26H52.9V17.24H56.13C57.33 17.24 58.25 16.98 58.88 16.46V16.46Z"
          fill="#071015"
        />
        <path
          d="M73.29 19.57H66.22L64.76 22.9H62.8L68.82 9.59998H70.7L76.74 22.9H74.75L73.29 19.57ZM72.62 18.05L69.75 11.53L66.88 18.05H72.62Z"
          fill="#071015"
        />
        <path
          d="M78.72 9.59998H84.32C85.73 9.59998 86.97 9.87998 88.06 10.44C89.15 11 90 11.78 90.6 12.79C91.2 13.8 91.5 14.95 91.5 16.26C91.5 17.57 91.2 18.72 90.6 19.73C90 20.74 89.15 21.52 88.06 22.08C86.97 22.64 85.72 22.92 84.32 22.92H78.72V9.59998ZM84.21 21.25C85.29 21.25 86.23 21.04 87.05 20.62C87.87 20.2 88.5 19.62 88.94 18.86C89.38 18.11 89.61 17.24 89.61 16.25C89.61 15.26 89.39 14.39 88.94 13.64C88.5 12.89 87.87 12.3 87.05 11.88C86.23 11.46 85.29 11.25 84.21 11.25H80.62V21.25H84.21V21.25Z"
          fill="#071015"
        />
        <path
          d="M104.07 21.25V22.9H94.42V9.59998H103.81V11.25H96.32V15.34H102.99V16.95H96.32V21.24H104.07V21.25Z"
          fill="#071015"
        />
        <path
          d="M115.35 22.9L111.36 17.41L107.43 22.9H105.24L110.24 16.08L105.55 9.59998H107.72L111.44 14.71L115.13 9.59998H117.2L112.51 16.02L117.55 22.9H115.36H115.35Z"
          fill="#071015"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_106">
          <rect
            width="48"
            height="22"
            fill="white"
            transform="translate(3 4)"
          />
        </clipPath>
        <clipPath id="clip1_16_106">
          <rect
            width="66.54"
            height="13.3"
            fill="white"
            transform="translate(51 9.59998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogoLight = () => {
  return (
    <svg
      width="190"
      height="32"
      viewBox="0 0 190 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.024 10.4499C132.173 10.9939 133.058 11.7715 133.686 12.7787C134.311 13.7858 134.624 14.9545 134.624 16.2846C134.624 17.6147 134.311 18.777 133.686 19.7714C133.061 20.7658 132.173 21.535 131.024 22.081C129.876 22.6271 128.545 22.8991 127.032 22.8991H122V9.63611H127.032C128.545 9.63611 129.876 9.90808 131.024 10.4542V10.4499ZM130.436 19.4952C131.262 18.7345 131.675 17.6636 131.675 16.2846C131.675 14.9056 131.262 13.8241 130.436 13.0443C129.611 12.2645 128.441 11.8756 126.931 11.8756H124.89V20.6362H126.931C128.444 20.6362 129.613 20.2558 130.436 19.4952Z"
        fill="white"
      />
      <path
        d="M136.984 10.6687C136.647 10.3713 136.479 10.0016 136.479 9.55748C136.479 9.11339 136.647 8.74368 136.984 8.44621C137.32 8.14874 137.742 8 138.252 8C138.762 8 139.184 8.14874 139.521 8.44621C139.857 8.74368 140.026 9.11552 140.026 9.55748C140.026 9.99943 139.857 10.3713 139.521 10.6687C139.184 10.9662 138.76 11.115 138.252 11.115C137.745 11.115 137.32 10.9662 136.984 10.6687ZM139.675 12.3686V22.8969H136.788V12.3686H139.675Z"
        fill="white"
      />
      <path
        d="M149.246 12.6639C149.892 12.9742 150.401 13.3757 150.773 13.8708V12.3686H153.681V22.9713C153.681 23.9466 153.469 24.8178 153.042 25.5848C152.615 26.3519 151.977 26.9596 151.123 27.41C150.27 27.8605 149.239 28.0857 148.031 28.0857C146.407 28.0857 145.079 27.7372 144.038 27.0403C143.001 26.3434 142.413 25.3936 142.274 24.1888H145.141C145.293 24.669 145.618 25.0536 146.121 25.3383C146.624 25.6231 147.23 25.7654 147.948 25.7654C148.787 25.7654 149.467 25.5338 149.989 25.0727C150.51 24.6095 150.773 23.9105 150.773 22.9734V21.3395C150.401 21.8346 149.889 22.2446 149.237 22.574C148.584 22.9033 147.837 23.0691 147 23.0691C146.038 23.0691 145.157 22.8417 144.359 22.3849C143.561 21.928 142.931 21.2864 142.473 20.4556C142.011 19.6269 141.781 18.6729 141.781 17.5956C141.781 16.5183 142.011 15.5877 142.473 14.7632C142.934 13.9388 143.559 13.3056 144.35 12.8637C145.141 12.4196 146.024 12.1986 147 12.1986C147.853 12.1986 148.602 12.3537 149.248 12.6639H149.246ZM150.36 15.9701C150.085 15.5069 149.714 15.1521 149.246 14.9056C148.778 14.6591 148.277 14.5359 147.74 14.5359C147.203 14.5359 146.709 14.657 146.255 14.8971C145.8 15.1372 145.434 15.4899 145.152 15.951C144.871 16.4142 144.73 16.9624 144.73 17.5956C144.73 18.2288 144.871 18.7834 145.152 19.2593C145.434 19.7353 145.805 20.0986 146.266 20.3514C146.728 20.6043 147.219 20.7318 147.74 20.7318C148.261 20.7318 148.778 20.6086 149.246 20.3621C149.714 20.1156 150.085 19.7608 150.36 19.2976C150.634 18.8343 150.773 18.2819 150.773 17.6338C150.773 16.9858 150.634 16.4333 150.36 15.9722V15.9701Z"
        fill="white"
      />
      <path
        d="M156.702 10.6687C156.365 10.3713 156.197 10.0016 156.197 9.55748C156.197 9.11339 156.365 8.74368 156.702 8.44621C157.039 8.14874 157.461 8 157.97 8C158.48 8 158.902 8.14874 159.239 8.44621C159.576 8.74368 159.744 9.11552 159.744 9.55748C159.744 9.99943 159.576 10.3713 159.239 10.6687C158.902 10.9662 158.478 11.115 157.97 11.115C157.463 11.115 157.039 10.9662 156.702 10.6687ZM159.393 12.3686V22.8969H156.506V12.3686H159.393Z"
        fill="white"
      />
      <path
        d="M165.602 14.555V19.6481C165.602 20.003 165.694 20.26 165.881 20.4173C166.065 20.5766 166.379 20.6553 166.819 20.6553H168.159V22.8969H166.344C163.911 22.8969 162.694 21.8069 162.694 19.629V14.555H161.333V12.3686H162.694V9.76569H165.602V12.3686H168.159V14.555H165.602Z"
        fill="white"
      />
      <path
        d="M170.191 14.7632C170.652 13.9388 171.277 13.3056 172.068 12.8637C172.859 12.4196 173.743 12.1986 174.718 12.1986C175.572 12.1986 176.317 12.358 176.955 12.6746C177.594 12.9912 178.106 13.3906 178.491 13.8708V12.3686H181.399V22.897H178.491V21.3586C178.12 21.8516 177.608 22.2616 176.955 22.5846C176.303 22.9076 175.549 23.0691 174.698 23.0691C173.736 23.0691 172.859 22.8417 172.068 22.3849C171.277 21.928 170.652 21.2864 170.191 20.4556C169.73 19.6269 169.499 18.6729 169.499 17.5956C169.499 16.5183 169.73 15.5877 170.191 14.7632ZM178.081 15.9701C177.806 15.5069 177.435 15.1521 176.967 14.9056C176.499 14.6591 175.998 14.5359 175.461 14.5359C174.924 14.5359 174.43 14.657 173.976 14.8971C173.521 15.1372 173.155 15.4899 172.873 15.951C172.592 16.4142 172.451 16.9624 172.451 17.5956C172.451 18.2288 172.592 18.7834 172.873 19.2593C173.155 19.7353 173.526 20.0986 173.987 20.3514C174.448 20.6043 174.94 20.7318 175.461 20.7318C175.982 20.7318 176.499 20.6086 176.967 20.3621C177.435 20.1156 177.806 19.7608 178.081 19.2976C178.355 18.8343 178.494 18.2819 178.494 17.6338C178.494 16.9858 178.355 16.4333 178.081 15.9722V15.9701Z"
        fill="white"
      />
      <path d="M187.114 8.83508V22.897H184.227V8.83508H187.114Z" fill="white" />
      <g clip-path="url(#clip0_93_283)">
        <path
          d="M36.0857 22.6823H24.3842C24.0747 19.2614 22.3356 16.2976 19.9187 14.5577C20.8914 13.9531 21.8935 13.5845 22.8662 13.5845C27.479 13.5992 35.452 17.551 36.0857 22.6823Z"
          fill="#2797FE"
        />
        <path
          d="M22.8661 13.5992C21.8935 13.5992 20.8913 13.9531 19.9186 14.5724C16.2048 16.8874 12.7562 22.7708 12.1078 26H3C4.6506 20.4115 12.4173 13.6139 18.2238 13.6139H22.8661V13.5992Z"
          fill="#007DF1"
        />
        <path
          d="M46.1513 4C38.5291 4 31.8471 8.01515 28.1008 14.0445C32.1522 15.4969 36.3686 18.5654 36.8225 22.2399C37.7067 13.8351 43.3512 6.84584 50.9999 4H46.1513Z"
          fill="#007DF1"
        />
      </g>
      <g clip-path="url(#clip1_93_283)">
        <path
          d="M59.89 22.9L57.02 18.82C56.67 18.85 56.39 18.86 56.18 18.86H52.9V22.91H51V9.59998H56.19C57.91 9.59998 59.27 10.01 60.26 10.83C61.25 11.65 61.74 12.79 61.74 14.23C61.74 15.26 61.49 16.13 60.98 16.85C60.47 17.57 59.75 18.1 58.81 18.43L61.96 22.89H59.89V22.9ZM58.88 16.46C59.51 15.94 59.83 15.2 59.83 14.24C59.83 13.28 59.51 12.54 58.88 12.03C58.25 11.52 57.33 11.26 56.13 11.26H52.9V17.24H56.13C57.33 17.24 58.25 16.98 58.88 16.46Z"
          fill="white"
        />
        <path
          d="M73.29 19.57H66.22L64.76 22.9H62.8L68.82 9.59998H70.7L76.74 22.9H74.75L73.29 19.57ZM72.62 18.05L69.75 11.53L66.88 18.05H72.62Z"
          fill="white"
        />
        <path
          d="M78.72 9.59998H84.32C85.73 9.59998 86.97 9.87998 88.06 10.44C89.15 11 90 11.78 90.6 12.79C91.2 13.8 91.5 14.95 91.5 16.26C91.5 17.57 91.2 18.72 90.6 19.73C90 20.74 89.15 21.52 88.06 22.08C86.97 22.64 85.72 22.92 84.32 22.92H78.72V9.59998ZM84.21 21.25C85.29 21.25 86.23 21.04 87.05 20.62C87.87 20.2 88.5 19.62 88.94 18.86C89.38 18.11 89.61 17.24 89.61 16.25C89.61 15.26 89.39 14.39 88.94 13.64C88.5 12.89 87.87 12.3 87.05 11.88C86.23 11.46 85.29 11.25 84.21 11.25H80.62V21.25H84.21Z"
          fill="white"
        />
        <path
          d="M104.07 21.25V22.9H94.42V9.59998H103.81V11.25H96.32V15.34H102.99V16.95H96.32V21.24H104.07V21.25Z"
          fill="white"
        />
        <path
          d="M115.35 22.9L111.36 17.41L107.43 22.9H105.24L110.24 16.08L105.55 9.59998H107.72L111.44 14.71L115.13 9.59998H117.2L112.51 16.02L117.55 22.9H115.36H115.35Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_283">
          <rect
            width="48"
            height="22"
            fill="white"
            transform="translate(3 4)"
          />
        </clipPath>
        <clipPath id="clip1_93_283">
          <rect
            width="66.54"
            height="13.3"
            fill="white"
            transform="translate(51 9.59998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Lightening = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.36566 36L8.45837 21.9H1.00628C0.631977 21.9 0.359755 21.7333 0.189616 21.4C0.019477 21.0667 0.019477 20.75 0.189616 20.45L12.6948 0H14.7875L12.6948 14.05H20.0448C20.4191 14.05 20.6999 14.2167 20.887 14.55C21.0742 14.8833 21.0827 15.2 20.9125 15.5L8.45837 36H6.36566Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const PiggyBank = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 18C28.5667 18 29.0417 17.8083 29.425 17.425C29.8083 17.0417 30 16.5667 30 16C30 15.4333 29.8083 14.9583 29.425 14.575C29.0417 14.1917 28.5667 14 28 14C27.4333 14 26.9583 14.1917 26.575 14.575C26.1917 14.9583 26 15.4333 26 16C26 16.5667 26.1917 17.0417 26.575 17.425C26.9583 17.8083 27.4333 18 28 18ZM12 13H22V10H12V13ZM5 38C3.86667 34.2 2.75 30.4083 1.65 26.625C0.55 22.8417 0 18.9667 0 15C0 11.9333 1.06667 9.33333 3.2 7.2C5.33333 5.06667 7.93333 4 11 4H21C21.9667 2.73333 23.1417 1.75 24.525 1.05C25.9083 0.35 27.4 0 29 0C29.8333 0 30.5417 0.291667 31.125 0.875C31.7083 1.45833 32 2.16667 32 3C32 3.2 31.975 3.4 31.925 3.6C31.875 3.8 31.8167 3.98333 31.75 4.15C31.6167 4.51667 31.4917 4.89167 31.375 5.275C31.2583 5.65833 31.1667 6.05 31.1 6.45L35.65 11H40V24.95L34.35 26.8L31 38H20V34H16V38H5ZM7.25 35H13V31H23V35H28.75L31.9 24.5L37 22.75V14H34.4L28 7.6C28.0333 6.76667 28.1417 5.95833 28.325 5.175C28.5083 4.39167 28.7 3.6 28.9 2.8C27.6333 3.13333 26.4333 3.625 25.3 4.275C24.1667 4.925 23.3 5.83333 22.7 7H11C8.8 7 6.91667 7.78333 5.35 9.35C3.78333 10.9167 3 12.8 3 15C3 18.4333 3.48333 21.7917 4.45 25.075C5.41667 28.3583 6.35 31.6667 7.25 35Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Cart = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.35 39.95C11.35 39.95 10.5 39.6 9.80005 38.9C9.10005 38.2 8.75005 37.35 8.75005 36.35C8.75005 35.35 9.10005 34.5 9.80005 33.8C10.5 33.1 11.35 32.75 12.35 32.75C13.35 32.75 14.2 33.1 14.9 33.8C15.6 34.5 15.95 35.35 15.95 36.35C15.95 37.35 15.6 38.2 14.9 38.9C14.2 39.6 13.35 39.95 12.35 39.95ZM32.35 39.95C31.35 39.95 30.5 39.6 29.8 38.9C29.1 38.2 28.75 37.35 28.75 36.35C28.75 35.35 29.1 34.5 29.8 33.8C30.5 33.1 31.35 32.75 32.35 32.75C33.35 32.75 34.2001 33.1 34.9001 33.8C35.6001 34.5 35.9501 35.35 35.9501 36.35C35.9501 37.35 35.6001 38.2 34.9001 38.9C34.2001 39.6 33.35 39.95 32.35 39.95ZM9.75005 6.95L15.25 18.35H29.65L35.9001 6.95H9.75005ZM8.25005 3.95H37.7001C38.4667 3.95 39.05 4.3 39.4501 5C39.8501 5.7 39.8501 6.4 39.4501 7.1L32.7001 19.25C32.3334 19.8833 31.8584 20.3917 31.2751 20.775C30.6917 21.1583 30.0501 21.35 29.35 21.35H14.2L11.4 26.55H35.9501V29.55H11.85C10.45 29.55 9.44172 29.0833 8.82505 28.15C8.20838 27.2167 8.21672 26.1667 8.85005 25L12.05 19.1L4.45005 3H0.550049V0H6.40005L8.25005 3.95ZM15.25 18.35H29.65H15.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Travel = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 32C2.13333 32 1.41667 31.7167 0.85 31.15C0.283333 30.5833 0 29.8667 0 29V21.35C1.23333 21.0833 2.25833 20.4583 3.075 19.475C3.89167 18.4917 4.3 17.3333 4.3 16C4.3 14.6667 3.89167 13.5 3.075 12.5C2.25833 11.5 1.23333 10.8833 0 10.65V3C0 2.13333 0.283333 1.41667 0.85 0.85C1.41667 0.283334 2.13333 0 3 0H37C37.8667 0 38.5833 0.283334 39.15 0.85C39.7167 1.41667 40 2.13333 40 3V29C40 29.8667 39.7167 30.5833 39.15 31.15C38.5833 31.7167 37.8667 32 37 32H3ZM3 29H37V3H3V8.45C4.3 9.31667 5.34167 10.4 6.125 11.7C6.90833 13 7.3 14.4333 7.3 16C7.3 17.5667 6.90833 19 6.125 20.3C5.34167 21.6 4.3 22.6833 3 23.55V29ZM13.4 23.75L31.8 18.7C32.3667 18.5333 32.7833 18.2667 33.05 17.9C33.3167 17.5333 33.3667 17.0667 33.2 16.5C33.0333 15.9333 32.7333 15.5417 32.3 15.325C31.8667 15.1083 31.3833 15.0667 30.85 15.2L25.7 16.55L17.4 8.45L14.8 9.05L19.95 18.1L14.4 19.65L11.8 17.5L10.35 18L13.4 23.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Cog = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 39 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 40V31.4C4.1 29.6667 2.625 27.6417 1.575 25.325C0.525 23.0083 0 20.5667 0 18C0 13 1.75 8.75 5.25 5.25C8.75 1.75 13 0 18 0C22.1667 0 25.8583 1.225 29.075 3.675C32.2917 6.125 34.3833 9.31667 35.35 13.25L38.1 24.15C38.2333 24.6167 38.15 25.0417 37.85 25.425C37.55 25.8083 37.15 26 36.65 26H32V33C32 33.8333 31.7083 34.5417 31.125 35.125C30.5417 35.7083 29.8333 36 29 36H24V40H21V33H29V23H34.7L32.45 14C31.65 10.7667 29.9 8.125 27.2 6.075C24.5 4.025 21.4333 3 18 3C13.8333 3 10.2917 4.44167 7.375 7.325C4.45833 10.2083 3 13.7333 3 17.9C3 20.0333 3.44167 22.0667 4.325 24C5.20833 25.9333 6.45 27.65 8.05 29.15L9 30.05V40H6ZM16.45 25.3H19.45L19.6 23.1C20 23.0333 20.375 22.8917 20.725 22.675C21.075 22.4583 21.3833 22.2167 21.65 21.95L23.75 22.65L25.15 20.25L23.65 19.05C23.8167 18.5833 23.9 18.1 23.9 17.6C23.9 17.1 23.8167 16.6167 23.65 16.15L25.15 14.95L23.75 12.55L21.65 13.25C21.3833 12.9833 21.0667 12.75 20.7 12.55C20.3333 12.35 19.9667 12.2 19.6 12.1L19.45 9.9H16.45L16.3 12.1C15.9333 12.2 15.5667 12.35 15.2 12.55C14.8333 12.75 14.5167 12.9833 14.25 13.25L12.15 12.55L10.75 14.95L12.25 16.15C12.0833 16.6167 12 17.1 12 17.6C12 18.1 12.0833 18.5833 12.25 19.05L10.75 20.25L12.15 22.65L14.25 21.95C14.5167 22.2167 14.825 22.4583 15.175 22.675C15.525 22.8917 15.9 23.0333 16.3 23.1L16.45 25.3ZM17.95 21.1C16.9833 21.1 16.1583 20.7583 15.475 20.075C14.7917 19.3917 14.45 18.5667 14.45 17.6C14.45 16.6333 14.7917 15.8083 15.475 15.125C16.1583 14.4417 16.9833 14.1 17.95 14.1C18.9167 14.1 19.7417 14.4417 20.425 15.125C21.1083 15.8083 21.45 16.6333 21.45 17.6C21.45 18.5667 21.1083 19.3917 20.425 20.075C19.7417 20.7583 18.9167 21.1 17.95 21.1Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const VerifyBadge = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 42L11.5 35.5L3.95 33.95L4.8 26.6L0 21L4.8 15.45L3.95 8.1L11.5 6.55L15.3 0L22 3.1L28.7 0L32.55 6.55L40.05 8.1L39.2 15.45L44 21L39.2 26.6L40.05 33.95L32.55 35.5L28.7 42L22 38.9L15.3 42ZM16.65 38.05L22 35.8L27.5 38.05L30.85 33.05L36.7 31.55L36.1 25.6L40.15 21L36.1 16.3L36.7 10.35L30.85 8.95L27.4 3.95L22 6.2L16.5 3.95L13.15 8.95L7.3 10.35L7.9 16.3L3.85 21L7.9 25.6L7.3 31.65L13.15 33.05L16.65 38.05ZM19.85 27.65L31.2 16.4L28.95 14.35L19.85 23.35L15.1 18.4L12.8 20.65L19.85 27.65Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Code = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.95 24.85L15.15 22.65L10.5 18L15.1 13.4L12.9 11.2L6.1 18L12.95 24.85ZM23.05 24.85L29.9 18L23.05 11.15L20.85 13.35L25.5 18L20.85 22.65L23.05 24.85ZM3 36C2.2 36 1.5 35.7 0.9 35.1C0.3 34.5 0 33.8 0 33V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H33C33.8 0 34.5 0.3 35.1 0.9C35.7 1.5 36 2.2 36 3V33C36 33.8 35.7 34.5 35.1 35.1C34.5 35.7 33.8 36 33 36H3ZM3 33H33V3H3V33ZM3 3V33V3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Cloud = () => {
  return (
    <svg
      width="44"
      height="32"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.55 32C7.61667 32 5.125 30.975 3.075 28.925C1.025 26.875 0 24.3833 0 21.45C0 18.85 0.833333 16.5667 2.5 14.6C4.16667 12.6333 6.28333 11.45 8.85 11.05C9.51667 7.81665 11.0833 5.17499 13.55 3.12499C16.0167 1.07499 18.8667 0.0499878 22.1 0.0499878C25.8333 0.0499878 28.9833 1.40832 31.55 4.12499C34.1167 6.84165 35.4 10.1 35.4 13.9V15.1C37.8 15.0333 39.8333 15.8083 41.5 17.425C43.1667 19.0417 44 21.0833 44 23.55C44 25.85 43.1667 27.8333 41.5 29.5C39.8333 31.1667 37.85 32 35.55 32H10.55ZM10.55 29H35.55C37.05 29 38.3333 28.4667 39.4 27.4C40.4667 26.3333 41 25.05 41 23.55C41 22.05 40.4667 20.7667 39.4 19.7C38.3333 18.6333 37.05 18.1 35.55 18.1H32.4V13.9C32.4 10.8667 31.3833 8.29999 29.35 6.19999C27.3167 4.09999 24.8333 3.04999 21.9 3.04999C18.9667 3.04999 16.475 4.09999 14.425 6.19999C12.375 8.29999 11.35 10.8667 11.35 13.9H10.4C8.33333 13.9 6.58333 14.625 5.15 16.075C3.71667 17.525 3 19.3167 3 21.45C3 23.55 3.73333 25.3333 5.2 26.8C6.66667 28.2667 8.45 29 10.55 29Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Speaker = () => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5 17.5V14.5H40V17.5H32.5ZM35 32L28.95 27.5L30.75 25.1L36.8 29.6L35 32ZM30.9 6.85L29.1 4.45L35 0L36.8 2.4L30.9 6.85ZM6.5 30V22H3C2.16667 22 1.45833 21.7083 0.875 21.125C0.291667 20.5417 0 19.8333 0 19V13C0 12.1667 0.291667 11.4583 0.875 10.875C1.45833 10.2917 2.16667 10 3 10H12L22 4V28L12 22H9.5V30H6.5ZM24 22.7V9.3C24.9 10.1 25.625 11.075 26.175 12.225C26.725 13.375 27 14.6333 27 16C27 17.3667 26.725 18.625 26.175 19.775C25.625 20.925 24.9 21.9 24 22.7ZM3 13V19H12.8L19 22.7V9.3L12.8 13H3Z"
        fill="currentColor"
      />
    </svg>
  );
};
