import { Carousel } from "flowbite-react";
import { CardItems } from "./card";
import { CardObj } from "./utilities/card-object";

export const SectionCarousel = () => {
  return (
    <div className="flex sm:hidden w-[344px] h-[300px]">
      <Carousel>
        {CardObj().map((item, index) => {
          return (
            <div key={index} className="px-4">
              <CardItems
                icon={item.icon}
                card_title={item.card_title}
                card_content={item.card_content}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
