import "./styles/App.scss";
import Main from "./pages/main";
import { SiteFooter } from "./components/footer";
import { Header } from "./components/header";

function App() {
  return (
    <div className="main">
      <Header />
      <Main />
      <SiteFooter />
    </div>
  );
}

export default App;
