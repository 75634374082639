import { Navbar } from "flowbite-react";
import { LogoDark } from "./icons";

export const Header = () => {
  return (
    <>
      <div className="container px-2 lg:px-12 bg-[#FFFFFF]">
        <Navbar fluid={true} rounded={true} id="mainNav">
          <Navbar.Brand href="/">
            <LogoDark />
          </Navbar.Brand>
          <div className="md:order-2 hidden md:flex sm:ml-auto">
            <div className="flex md:order-2">
              <a
                href="/#contact"
                className="main-button inline-flex items-center"
              >
                <span className="text-sm font-medium"> Get in touch </span>
              </a>
            </div>
          </div>

          <Navbar.Collapse>
            <Navbar.Link href="/#services">Our Services</Navbar.Link>
            <Navbar.Link href="/#about">About Us</Navbar.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};
