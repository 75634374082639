import { Cloud, Code, Cog, Lightening, Speaker, VerifyBadge } from "../icons";

export const CardObj = () => {
  const cardItems = [
    {
      icon: <Speaker />,
      card_title: "Digital Branding",
      card_content:
        "We support our clients with all aspects of digital branding, email communications and online marketing.",
    },
    {
      icon: <Lightening />,
      card_title: "UI & UX Design",
      card_content:
        "We build intuitive interfaces for your product that leads to higher user satisfaction and increased conversion rates.",
    },
    {
      icon: <Cog />,
      card_title: "Customer Experience",
      card_content:
        "We build tailored end-to-end support throughout the customer lifecycle.",
    },
    {
      icon: <VerifyBadge />,
      card_title: "Quality Assurance",
      card_content:
        "We build tailored end-to-end support throughout the customer lifecycle.",
    },
    {
      icon: <Code />,
      card_title: "Backend Development",
      card_content:
        "We build robust solutions allowing for scalability, advanced data structures, and best-in-class security.",
    },
    {
      icon: <Cloud />,
      card_title: "Cloud infrastructure",
      card_content:
        "We fast track your best-practice environment in a cloud-native way across multiple cloud providers.",
    },
  ];

  return cardItems;
};
