export const Hero = (props) => {
  return (
    <section className="container">
      <div className="App-hero mx-auto max-w-screen-xl py-12 px-4 sm:px-6 lg:py-16 lg:px-16">
        <div className="items-center justify-center grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative overflow-hidden order-last">
            <img
              alt="hero"
              src={props.img}
              className="inset-0 object-cover rounded-[40px] w-[514px] h-[518px]"
            />
          </div>
          <div className="lg:py-20 max-w-[554px]">
            <h1> {props.title}</h1>

            <p className="mt-4 content_1 text-prmaryDark">{props.desc}</p>

            <div className="mt-12">
              <a
                href="/#contact"
                className="main-button inline-flex items-center !bg-primaryDark"
              >
                <span className="text-sm font-medium"> Get in touch </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
