import { Footer } from "flowbite-react";
import { LogoLight } from "./icons";

export const SiteFooter = () => {
  return (
    <Footer>
      <div className="w-full mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-16">
        <div className="w-full py-6 px-4 sm:flex sm:items-center sm:justify-between">
          <a href="/">
            <LogoLight />
          </a>
        </div>
        <div className="w-full py-28 px-4 sm:flex sm:items-center sm:justify-between text-primaryLight footer-content">
          All contents @ 2022 Radex Markets. All rights reserved.
        </div>
      </div>
    </Footer>
  );
};
