export const Section = (props) => {
  return (
    <section
      className={`container ${props.customStyle ? props.customStyle : ""}`}
    >
      <div className="App-hero mx-auto max-w-screen-xl py-12 px-4 sm:px-6 lg:py-16 lg:px-16 my-10">
        {props.title ? (
          <h2 className="block text-center sm:mb-14 text-3xl font-bold tracking-tight sm:text-5xl">
            {props.title}
          </h2>
        ) : (
          ""
        )}
        <div className="max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8 sm:flex sm:items-center sm:justify-center">
          {props.content}
        </div>
      </div>
    </section>
  );
};
